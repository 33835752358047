





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { JSONPayload } from "avalanche/dist/utils";
import { IGenericNft } from "@/components/wallet/studio/mint/types";

@Component
export default class UtfPayloadView extends Vue {
  @Prop() payload!: JSONPayload;
  isError = false;
  jsonData: IGenericNft | null = null;
  isImage = false;
  isVideo = false;

  get content(): string {
    return this.payload.getContent().toString();
  }

  get desc() {
    return this.jsonData?.desc;
  }

  get img() {
    return this.jsonData?.img;
  }

  get title() {
    return this.jsonData?.title;
  }

  // get data() {
  //     return JSON.parse(this.content)
  // }
  mounted() {
    try {
      this.jsonData = JSON.parse(this.content).avalanche;
    } catch (e) {
      this.isError = true;
    }
  }

  @Watch("payload")
  onPayloadChange(val: JSONPayload) {
    try {
      this.jsonData = JSON.parse(this.content).avalanche;
    } catch (e) {
      this.isError = true;
    }
  }
}
