

















import { Vue, Component, Prop } from "vue-property-decorator";
import { AvaNftFamily } from "@/js/AvaNftFamily";
import { UTXO } from "avalanche/dist/apis/avm";
import { getPayloadFromUTXO } from "@/helpers/helper";
import NftPayloadView from "@/components/misc/NftPayloadView/NftPayloadView.vue";
@Component({
  components: {
    NftPayloadView,
  },
})
export default class NftFamilyCardsPreview extends Vue {
  @Prop() utxos!: UTXO[];
  @Prop({ default: false }) spread!: boolean;
  @Prop() max!: number;

  get rotateDeg() {
    if (!this.spread) {
      return 5;
    } else {
      let len = this.payloads.length;
      let maxLen = this.max;
      return 25 * ((maxLen - len) / maxLen) + 5;
    }
  }

  get payloads() {
    return this.utxos.map((utxo) => {
      return getPayloadFromUTXO(utxo);
    });
  }
}
