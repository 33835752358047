












import { Vue, Component, Model, Prop } from "vue-property-decorator";

@Component
export default class NftPayloadAllow extends Vue {
  @Prop({}) nftID!: string;
  @Prop({ default: false }) isSmall!: boolean;
  @Model("change", { type: Boolean }) readonly isShow!: boolean;

  show() {
    this.$store.commit("Assets/whitelistNFT", this.nftID);
    this.$emit("change", true);
  }
}
