















import { Component, Prop, Vue } from "vue-property-decorator";
import { URLPayload } from "avalanche/dist/utils";

@Component
export default class UrlPayloadView extends Vue {
  @Prop() payload!: URLPayload;

  img_types = [
    "jpeg",
    "jpg",
    "gif",
    "png",
    "apng",
    "svg",
    "bmp",
    "ico",
    "webp",
  ];
  valid_types = this.img_types.concat(["pdf"]);
  isImage = false;
  isVideo = false;

  get url() {
    return this.payload.getContent().toString();
  }

  get fileType(): string | null {
    let url = this.url;

    let split = url.split(".");

    // Couldn't find extension
    if (split.length === 1) return null;

    let extension: string = split[split.length - 1];

    if (!this.valid_types.includes(extension)) return null;
    return extension;
  }
}
