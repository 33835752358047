













import { Vue, Component, Prop, Model } from "vue-property-decorator";

@Component
export default class RadioButtons extends Vue {
  @Prop() labels!: string[];
  @Prop() keys!: string[];

  @Model("change", { type: String }) readonly selection!: string;

  select(val: string) {
    this.$emit("change", val);
  }
}
