










import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

//@ts-ignore
import langMap from "@/locales/lang_map";
//@ts-ignore
import CountryFlag from "vue-country-flag";

import { LanguageItem } from "@/components/misc/LanguageSelect/types";

interface FLAG_DICT {
  [key: string]: string;
}
const FLAGS_OVERRIDE: FLAG_DICT = {
  en: "us",
  zh_hant: "cn",
  zh_hans: "cn",
  cs: "cz",
  ca: "es-ca",
  uk: "ua",
  af: "za",
  ar: "ae",
  da: "dk",
  el: "gr",
  he: "il",
  nb: "no",
  sr: "rs",
  sv: "se",
  ja: "jp",
};

@Component({
  components: {
    CountryFlag,
  },
})
export default class LanguageSelect extends Vue {
  locale = "en";

  mounted() {
    this.locale = this.$root.$i18n.locale;
  }

  @Watch("locale")
  onSelectedChange(val: string) {
    this.$root.$i18n.locale = val;
    localStorage.setItem("lang", val);
  }

  get flag() {
    let selCode = this.locale;

    if (FLAGS_OVERRIDE[selCode]) {
      return FLAGS_OVERRIDE[selCode];
    } else {
      return selCode;
    }
  }

  get items(): LanguageItem[] {
    let res = [];

    let messages = this.$root.$i18n.messages;
    for (var langCode in messages) {
      let data = langMap[langCode];

      res.push({
        code: langCode,
        name: data.name,
        nativeName: data.nativeName,
      });
    }
    return res;
  }
}
