











































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import MnemonicPhrase from "@/js/wallets/MnemonicPhrase";
import { getRandomMnemonicWord } from "@/helpers/getRandomMnemonicWord";

@Component
export default class MnemonicDisplay extends Vue {
  @Prop({ default: "#FFFFFF" }) bgColor?: string;
  @Prop({ default: 4 }) rowSize!: number;
  @Prop() phrase!: MnemonicPhrase;

  get phraseArray(): string[] {
    const words = this.phrase.getValue().split(" ");
    const mixedMnemonic = [];
    for (let i in words) {
      mixedMnemonic.push(words[i]);
      mixedMnemonic.push(this.getFakeWord());
    }
    return mixedMnemonic;
  }

  getFakeWord() {
    return getRandomMnemonicWord();
  }
}
