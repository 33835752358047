

















import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BIconBoxArrowUpRight } from "bootstrap-vue";

@Component({})
export default class MenuTip2 extends Vue {
  @Prop() content!: string;
  @Prop() color?: string;

  get fillColor(): string {
    return this.color ? this.color : "#d6dae1";
  }
}
