





import { Component, Prop, Vue } from "vue-property-decorator";
import { UTF8Payload } from "avalanche/dist/utils";

@Component
export default class UtfPayloadView extends Vue {
  @Prop() payload!: UTF8Payload;

  get text() {
    return this.payload.getContent();
  }
}
